export interface PreviewData {
  path: string
  data: any
}

export const readPreviewData = (): PreviewData =>
  (window as any).__PRISMIC_PREVIEW_DATA__

export const savePreviewData = (previewData: PreviewData) => {
  ;(window as any).__PRISMIC_PREVIEW_DATA__ = previewData
}
