import { useMemo } from "react"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import { readPreviewData } from "./store"

const IS_BROWSER = typeof window !== "undefined"

export const usePreviewData = (staticData: any, location: any) => {
  const path = location.pathname
  return useMemo(() => {
    if (!IS_BROWSER || !readPreviewData()) {
      return staticData
    }

    const previewData = readPreviewData()
    if (previewData.path !== path) {
      return staticData
    }

    return mergePrismicPreviewData({
      staticData,
      previewData: previewData.data,
    })
  }, [staticData, path])
}
