import React from "react"
import { PageProps } from "gatsby"
import { usePreviewData } from "./usePreviewData"

export const withPrismicPreview = <TProps extends PageProps>(
  WrappedComponent: React.ComponentType<TProps> | any
): React.ComponentType<TProps> => {
  const WithPreview = (props: TProps) => {
    const data = usePreviewData(props.data, props.location)
    return <WrappedComponent {...props} data={data} />
  }

  WithPreview.displayName = `withPreview(${
    WrappedComponent.displayName ?? "Page"
  })`

  return WithPreview
}
