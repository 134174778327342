import React from "react"
import styled, { keyframes } from "styled-components"

const wave = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Root = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Water = styled.div`
  width: 400px;
  height: 400px;
  background-color: skyblue;
  border-radius: 50%;
  position: relative;
  box-shadow: inset 0 0 30px 0 rgba(0, 0, 0, 0.5),
    0 4px 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    top: -150px;
    background-color: #fff;
  }
  &:before {
    border-radius: 45%;
    background: rgba(255, 255, 255, 0.7);
    animation: ${wave} 5s linear infinite;
  }
  &:after {
    border-radius: 35%;
    background: rgba(255, 255, 255, 0.3);
    animation: ${wave} 5s linear infinite;
  }
`

const WaterLoader = () => {
  return (
    <Root>
      <Water />
    </Root>
  )
}

export default WaterLoader
